<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
    >
      <v-col
        cols="12"
        class="ml-md-auto"
      >
        <v-row dense justify="end">
          <v-col cols="auto">
            <filter-list
              :filters="filters"
              @fetchBasp="key_list += 1"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-text-field
              dense
              hide-details
              outlined
              @keyup.native.enter="searchShipment"
              placeholder="Cari Nomor Pesanan"
              class="caption"
              v-model="search"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="searchShipment">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <br>
    <List
      :key="key_list"
      :filters="filters"
    />
  </v-container>
</template>

<script>
import FilterList from './FilterList.vue';
import List from './List.vue';

export default {
  name: 'damage-and-missing-page',
  components: {
    List,
    FilterList,
  },
  data() {
    return {
      key_list: 0,
      search: this.$route.query.title || '',
      filters: {
        title: this.$route.query.title || '',
        baspTitle: this.$route.query.baspTitle || '',
        shipperId: this.$route.query.shipperId || '',
        transporterId: this.$route.query.transporterId || '',
        transportNo: this.$route.query.transportNo || '',
        driverName: this.$route.query.driverName || '',
        transportTypeName: this.$route.query.transportTypeName || '',
      },
    };
  },
  methods: {
    searchShipment() {
      if (this.search !== this.$route.query.search) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
            title: this.search,
          },
        });
        this.filters.title = this.search;
      }
      this.key_list += 1;
    },
  },
};
</script>

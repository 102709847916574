<template>
  <section>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="items"
      item-key="id"
      :options.sync="pagination"
      @click:row="rowClicked"
      :server-items-length="totalItems"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
    >
      <template v-slot:[`item.shipmentTitle`]=items>
        <span>{{ items.item.shipmentTitle }}</span>
        <br>
        <span>{{dateFormat(items.item.cargoPickupDate) }}</span>
      </template>
      <template v-slot:[`item.transportName`]=items>
        <span>{{ items.item.transportName }}</span>
        <br>
        <span>{{ items.item.platLicense }}</span>
      </template>
      <template v-slot:[`item.driverName`]=items>
        <span>{{ items.item.driverName }}</span>
        <br>
        <span>{{ items.item.driverPhone }}</span>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          {{$_strings.damageAndMissing.pageName}}
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
    <DialogDetail
      ref="dialogDetail"
    />
  </section>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import DialogDetail from './Dialog/Detail.vue';

export default {
  name: 'damage-and-missing-page',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogDetail,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      headers: [
        {
          text: this.$_strings.damageAndMissing.headerCatatan,
          value: 'baspTitle',
        },
        {
          text: this.$_strings.damageAndMissing.headerNoPesanan,
          value: 'shipmentTitle',
        },
        {
          text: this.$_strings.damageAndMissing.headerNamaCustomer,
          value: 'shipperName',
        },
        {
          text: this.$_strings.damageAndMissing.headerRekanan3PL,
          value: 'transporterName',
        },
        {
          text: this.$_strings.damageAndMissing.headerKendaraan,
          value: 'transportName',
        },
        {
          text: this.$_strings.damageAndMissing.headerSopir,
          value: 'driverName',
        },
      ],
      pagination: this.defaultPagination(),
      totalItems: 0,
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchBasp();
      },
      deep: true,
    },
  },
  methods: {
    skipEmptyStringObject,
    dateFormat,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    toggleLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    async rowClicked(e, { item }) {
      try {
        this.$root.$loading.show();
        const result = await this.$_services.damageAndMissing.baspDetail(item.baspId);
        this.$refs.dialogDetail.dialog = true;
        this.$refs.dialogDetail.items = result.data;
      } finally {
        this.$root.$loading.hide();
      }
    },
    async fetchBasp() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ defaultValue: 'createdAt,DESC', sortBy, sortDesc }),
        ...this.filters,
      };
      try {
        this.toggleLoading(true);
        const res = await this.$_services.damageAndMissing.baspList(this.skipEmptyStringObject(filters));
        this.items = res.data.contents;
        this.totalItems = res.data.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
  },
};
</script>

<template>
  <div class="text-center d-flex">
    <v-menu
      bottom
      :close-on-content-click="closeOnContentClick"
      width="300"
      offset-y
      z-index="1"
      allow-overflow
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          hide-details
          height="10"
          class="caption"
          prepend-inner-icon="mdi-filter"
          :placeholder="$_strings.order.FILTER"
          outlined
          dense
          readonly
          v-bind="attrs"
          @click="closeOnContentClick = false"
          v-on="on"
        ></v-text-field>
      </template>
      <v-list
        width="300"
        height="500"
        class="overflow-auto"
      >
        <section>
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Catatan</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <v-text-field
                dense
                outlined
                class="caption"
                placeholder="Semua Catatan"
                v-model="form.baspTitle"
                hide-details
                clearable
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section v-for="item in itemsFilterAutoComplete" :key="item.id">
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">{{item.title}}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <common-auto-complete-items
                :type="item.type"
                :searchName="item.searchName"
                :item-value="item.value"
                :item-text="item.text"
                hide-details
                height="10"
                v-model="form[item.vModel]"
                :placeholder="item.placeholder"
                dense
                outlined
                class="caption"
                clearable
                @updateItems="item.updateItems"
                :items="itemsFilter[item.items]"
                :filter="pageFilters[item.filters]"
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section>
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Sopir</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <v-text-field
                dense
                outlined
                class="caption"
                placeholder="Semua Sopir"
                v-model="form.driverName"
                hide-details
                clearable
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section>
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Nomor Kendaraan</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <v-text-field
                dense
                outlined
                class="caption"
                placeholder="Semua Kendaraan"
                v-model="form.transportNo"
                hide-details
                clearable
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section>
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Tipe Kendaraan</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <v-text-field
                dense
                outlined
                class="caption"
                placeholder="Semua Tipe Kendaraan"
                v-model="form.transportTypeName"
                hide-details
                clearable
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section class="pl-2 pr-2">
          <v-btn
            depressed
            color="primary"
            block
            @click="setFilter"
          >
            {{$_strings.common.APPLY}}
          </v-btn>
          <v-btn
            depressed
            class="mt-2 white--text"
            color="red"
            block
            @click="closeOnContentClick = true"
          >
            {{$_strings.common.CLOSE}}
          </v-btn>
        </section>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.setItemsFromQueryString();
  },
  data() {
    return {
      itemsFilterAutoComplete: [
        {
          id: 'customer',
          title: 'Nama Shipper',
          type: 'masterShipper',
          searchName: 'companyName',
          placeholder: 'Semua Shipper',
          text: 'companyName',
          value: 'id',
          vModel: 'shipperId',
          items: 'itemsCustomers',
          updateItems: this.updateCustomerItems,
          filters: 'shipper',
        },
        {
          id: 'transporter',
          title: 'Rekanan 3PL',
          type: 'masterPartner',
          searchName: 'companyName',
          placeholder: 'Semua Rekanan 3PL',
          text: 'companyName',
          value: 'id',
          vModel: 'transporterId',
          items: 'itemsTransporter',
          updateItems: this.updateTransporterItems,
          filters: 'transporter',
        },
      ],
      itemsFilter: {
        itemsCustomers: [],
        itemsTransporter: [],
      },
      pageFilters: {
        transporter: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        shipper: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
      },
      form: {
        baspTitle: this.$route.query.baspTitle || '',
        shipper: this.$route.query.shipper || '',
        shipperId: +this.$route.query.shipperId || '',
        transporter: this.$route.query.transporter || '',
        transporterId: +this.$route.query.transporterId || '',
        transportNo: this.$route.query.transportNo || '',
        driverName: this.$route.query.driverName || '',
        transportTypeName: this.$route.query.transportTypeName || '',
      },
      closeOnContentClick: false,
    };
  },
  methods: {
    setItemsFromQueryString() {
      const {
        shipperId,
        shipper,
        transporterId,
        transporter,
      } = this.form;
      if (shipper) {
        this.itemsFilter.itemsCustomers = [{
          companyName: shipper,
          id: +shipperId,
        }];
      }
      if (transporter) {
        this.itemsFilter.itemsTransporter = [{
          companyName: transporter,
          id: +transporterId,
        }];
      }
    },
    updateCustomerItems(items) {
      this.itemsFilter.itemsCustomers = [...this.itemsFilter.itemsCustomers, ...items];
    },
    updateTransporterItems(items) {
      this.itemsFilter.itemsTransporter = [...this.itemsFilter.itemsTransporter, ...items];
    },
    setFilter() {
      this.closeOnContentClick = true;
      const {
        baspTitle,
        shipperId,
        transporterId,
        transportNo,
        driverName,
        transportTypeName,
      } = this.form;
      const oldFilters = { ...this.filters };
      this.filters.baspTitle = baspTitle;
      this.filters.shipperId = shipperId;
      this.filters.transporterId = transporterId;
      this.filters.transportNo = transportNo;
      this.filters.driverName = driverName;
      this.filters.transportTypeName = transportTypeName;
      if (this.itemsFilter.itemsCustomers.find((shipper) => shipper.id === +shipperId)) {
        this.form.shipper = this.itemsFilter.itemsCustomers.find((shipper) => shipper.id === +shipperId).companyName;
      }
      if (this.itemsFilter.itemsTransporter.find((transporter) => transporter.id === +transporterId)) {
        this.form.transporter = this.itemsFilter.itemsTransporter.find((transporter) => transporter.id === +transporterId).companyName;
      }
      if (JSON.stringify(oldFilters) !== JSON.stringify(this.filters)) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            shipper: this.form.shipper,
            transporter: this.form.transporter,
            ...this.filters,
          },
        });
      }
      this.$emit('fetchBasp');
    },
  },
};
</script>
